define("driver-portal/assetsMap", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "/assets/branded/bolt/img/logo-driver": "/assets/branded/bolt/img/logo-driver.svg",
    "/assets/branded/bolt/img/logo-white": "/assets/branded/bolt/img/logo-white.svg",
    "/assets/branded/bolt/img/new-logo-driver": "/assets/branded/bolt/img/new-logo-driver.svg",
    "/assets/branded/hopp/img/logo-driver": "/assets/branded/hopp/img/logo-driver.svg",
    "/assets/branded/hopp/img/logo-white": "/assets/branded/hopp/img/logo-white.svg",
    "/assets/branded/hopp/img/new-logo-driver": "/assets/branded/hopp/img/new-logo-driver.svg",
    "/assets/fonts/ElegantIcons": "/assets/fonts/ElegantIcons.woff",
    "/assets/fonts/EuclidCircularB-Regular-WebM": "/assets/fonts/EuclidCircularB-Regular-WebM.woff2",
    "/assets/fonts/EuclidCircularB-Semibold-WebM": "/assets/fonts/EuclidCircularB-Semibold-WebM.woff2",
    "/assets/fonts/fontawesome-webfont": "/assets/fonts/fontawesome-webfont.woff",
    "/assets/fonts/FontAwesome": "/assets/fonts/FontAwesome.otf",
    "/assets/fonts/glyphicons-halflings-regular": "/assets/fonts/glyphicons-halflings-regular.woff",
    "/assets/fonts/toolkit-entypo": "/assets/fonts/toolkit-entypo.woff2",
    "/assets/img/arrow": "/assets/img/arrow.svg",
    "/assets/img/car-pic-placeholder": "/assets/img/car-pic-placeholder.png",
    "/assets/img/circle-k-logo": "/assets/img/circle-k-logo.svg",
    "/assets/img/cross": "/assets/img/cross.svg",
    "/assets/img/driver-app-store-badges/app-store": "/assets/img/driver-app-store-badges/app-store.svg",
    "/assets/img/driver-app-store-badges/google-play": "/assets/img/driver-app-store-badges/google-play.svg",
    "/assets/img/driver-app-store-badges/huawei-app-gallery": "/assets/img/driver-app-store-badges/huawei-app-gallery.svg",
    "/assets/img/driver-consent": "/assets/img/driver-consent.svg",
    "/assets/img/driver": "/assets/img/driver.svg",
    "/assets/img/drivers-bg-1": "/assets/img/drivers-bg-1.jpg",
    "/assets/img/drivers-bg-2": "/assets/img/drivers-bg-2.jpg",
    "/assets/img/drivers-bg-3": "/assets/img/drivers-bg-3.jpg",
    "/assets/img/facebook-icon": "/assets/img/facebook-icon.svg",
    "/assets/img/faq-item-collapse": "/assets/img/faq-item-collapse.svg",
    "/assets/img/faq-item-expand": "/assets/img/faq-item-expand.svg",
    "/assets/img/guide-app": "/assets/img/guide-app.png",
    "/assets/img/identification-error-camera": "/assets/img/identification-error-camera.svg",
    "/assets/img/identification-error-no-connection": "/assets/img/identification-error-no-connection.svg",
    "/assets/img/identification-error-selfie-failed": "/assets/img/identification-error-selfie-failed.svg",
    "/assets/img/identification-error-timeout": "/assets/img/identification-error-timeout.svg",
    "/assets/img/identity-authentication": "/assets/img/identity-authentication.svg",
    "/assets/img/identity-license-photo": "/assets/img/identity-license-photo.svg",
    "/assets/img/identity-verification": "/assets/img/identity-verification.svg",
    "/assets/img/info": "/assets/img/info.svg",
    "/assets/img/lock16": "/assets/img/lock16.png",
    "/assets/img/minus": "/assets/img/minus.png",
    "/assets/img/plus": "/assets/img/plus.png",
    "/assets/img/register/devices-app": "/assets/img/register/devices-app.png",
    "/assets/img/register/step1": "/assets/img/register/step1.jpg",
    "/assets/img/register/step2": "/assets/img/register/step2.jpg",
    "/assets/img/register/step3": "/assets/img/register/step3.jpg",
    "/assets/img/ride-receipt/document-loading": "/assets/img/ride-receipt/document-loading.png",
    "/assets/img/shield": "/assets/img/shield.svg",
    "/assets/img/vat-status/document": "/assets/img/vat-status/document.png",
    "/assets/img/vat-status/heart-in-ellipse": "/assets/img/vat-status/heart-in-ellipse.png",
    "/assets/img/verification-failed": "/assets/img/verification-failed.svg",
    "/assets/img/verification-in-progress": "/assets/img/verification-in-progress.svg",
    "/assets/img/verification-success": "/assets/img/verification-success.svg",
    "/assets/img/whatsapp-logo": "/assets/img/whatsapp-logo.svg",
    "/assets/img/why-benefit-1": "/assets/img/why-benefit-1.svg",
    "/assets/img/why-benefit-2": "/assets/img/why-benefit-2.svg",
    "/assets/img/why-benefit-3": "/assets/img/why-benefit-3.svg",
    "/driverapp/magic-login-partner": "/driverapp/magic-login-partner.html",
    "/driverapp/magic-login": "/driverapp/magic-login.html",
    "/driverapp/training-completed": "/driverapp/training-completed.html",
    "/favicon": "/favicon.ico",
    "/languages/ar-eg/translations": "/languages/ar-eg/translations.json",
    "/languages/ar-iq/translations": "/languages/ar-iq/translations.json",
    "/languages/ar-sa/translations": "/languages/ar-sa/translations.json",
    "/languages/az-az/translations": "/languages/az-az/translations.json",
    "/languages/bn-bd/translations": "/languages/bn-bd/translations.json",
    "/languages/cs-cz/translations": "/languages/cs-cz/translations.json",
    "/languages/da-dk/translations": "/languages/da-dk/translations.json",
    "/languages/de-at/translations": "/languages/de-at/translations.json",
    "/languages/de-de/translations": "/languages/de-de/translations.json",
    "/languages/el-cy/translations": "/languages/el-cy/translations.json",
    "/languages/en-au/translations": "/languages/en-au/translations.json",
    "/languages/en-ca/translations": "/languages/en-ca/translations.json",
    "/languages/en-gb/translations": "/languages/en-gb/translations.json",
    "/languages/en-gh/translations": "/languages/en-gh/translations.json",
    "/languages/en-ie/translations": "/languages/en-ie/translations.json",
    "/languages/en-ke/translations": "/languages/en-ke/translations.json",
    "/languages/en-mt/translations": "/languages/en-mt/translations.json",
    "/languages/en-ng/translations": "/languages/en-ng/translations.json",
    "/languages/en-nz/translations": "/languages/en-nz/translations.json",
    "/languages/es-es/translations": "/languages/es-es/translations.json",
    "/languages/es-mx/translations": "/languages/es-mx/translations.json",
    "/languages/et-ee/translations": "/languages/et-ee/translations.json",
    "/languages/fi-fi/translations": "/languages/fi-fi/translations.json",
    "/languages/fr-be/translations": "/languages/fr-be/translations.json",
    "/languages/fr-fr/translations": "/languages/fr-fr/translations.json",
    "/languages/gu-in/translations": "/languages/gu-in/translations.json",
    "/languages/hi-in/translations": "/languages/hi-in/translations.json",
    "/languages/hr-hr/translations": "/languages/hr-hr/translations.json",
    "/languages/hu-hu/translations": "/languages/hu-hu/translations.json",
    "/languages/hy-am/translations": "/languages/hy-am/translations.json",
    "/languages/it-it/translations": "/languages/it-it/translations.json",
    "/languages/ka-ge/translations": "/languages/ka-ge/translations.json",
    "/languages/ky-kg/translations": "/languages/ky-kg/translations.json",
    "/languages/lt-lt/translations": "/languages/lt-lt/translations.json",
    "/languages/lv-lv/translations": "/languages/lv-lv/translations.json",
    "/languages/mr-in/translations": "/languages/mr-in/translations.json",
    "/languages/ms-my/translations": "/languages/ms-my/translations.json",
    "/languages/my-mm/translations": "/languages/my-mm/translations.json",
    "/languages/ne-np/translations": "/languages/ne-np/translations.json",
    "/languages/nl-be/translations": "/languages/nl-be/translations.json",
    "/languages/nl-nl/translations": "/languages/nl-nl/translations.json",
    "/languages/no-no/translations": "/languages/no-no/translations.json",
    "/languages/pl-pl/translations": "/languages/pl-pl/translations.json",
    "/languages/pt-pt/translations": "/languages/pt-pt/translations.json",
    "/languages/ro-ro/translations": "/languages/ro-ro/translations.json",
    "/languages/ru-lv/translations": "/languages/ru-lv/translations.json",
    "/languages/ru-pl/translations": "/languages/ru-pl/translations.json",
    "/languages/ru-ru/translations": "/languages/ru-ru/translations.json",
    "/languages/sk-sk/translations": "/languages/sk-sk/translations.json",
    "/languages/sr-rs/translations": "/languages/sr-rs/translations.json",
    "/languages/sv-se/translations": "/languages/sv-se/translations.json",
    "/languages/sw-tz/translations": "/languages/sw-tz/translations.json",
    "/languages/ta-in/translations": "/languages/ta-in/translations.json",
    "/languages/th-th/translations": "/languages/th-th/translations.json",
    "/languages/uk-ua/translations": "/languages/uk-ua/translations.json",
    "/languages/ur-pk/translations": "/languages/ur-pk/translations.json",
    "/robots": "/robots.txt"
  };
  _exports.default = _default;
});