define("driver-portal/assetsMapConfig", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "fingerprint": {
      "prepend": "https://partners.gethopp.com/v12.59.0/",
      "enabled": true,
      "extensions": ["js", "css", "png", "jpg", "gif", "map", "ico", "svg"]
    }
  };
  _exports.default = _default;
});